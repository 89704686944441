import { RouteRecordRaw } from "vue-router";
import AboutView from "@/views/DetialView.vue";
import ImageView from "@/views/ImageView.vue";
import DetialView from "@/views/DetialView.vue";

export const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/user",
  //   name: "用户模块",
  //   component: UserLayout,
  // },
  {
    path: "/",
    name: "首页",
    component: ImageView,
  },
  {
    path: "/img/:id",
    name: "合格检验证明",
    component: ImageView,
  },
  {
    path: "/detail/:id",
    name: "产品基本信息",
    component: DetialView,
  },
];
