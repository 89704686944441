<template>
  <div class="mainApp">
    <a-layout>
      <a-layout-header style="height: 20%">
        <img
          name="cpjbxx_r1_c1"
          src="http://www.pzcodee.cn/pwb/xna4824em/cpjbxx_r1_c1.jpg"
          width="100%"
          border="0"
          id="cpjbxx_r1_c1"
          alt=""
        />
        <div style="width: 100%; display: flex; justify-content: space-between">
          <a-button
            style="font-size: 12px; word-spacing: 20px"
            :style="{
              width: '50%',
              height: '45px',
              color: selectedButton === 'left' ? 'white' : 'black',
              backgroundColor:
                selectedButton === 'left' ? '#165DFF' : '#e5e6eb',
            }"
            @click="selectButton('left')"
          >
            合格检验证明
          </a-button>
          <a-button
            style="font-size: 12px"
            :style="{
              width: '50%',
              height: '45px',
              color: selectedButton === 'right' ? 'white' : 'black',
              backgroundColor:
                selectedButton === 'right' ? '#165DFF' : '#e5e6eb',
            }"
            @click="selectButton('right')"
          >
            产品基本信息
          </a-button>
        </div>
      </a-layout-header>
      <a-layout-content style="min-height: 80%">
        <router-view />
      </a-layout-content>
      <a-layout-footer>
        <div>
          <img style="width: 100%" src="http://battery.fzoj.fun/footer.png" />
        </div>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();
const selectedButton = computed(() => store.state.selectedButton);

const selectButton = (button: "left" | "right") => {
  if (button === "left") {
    router.push("/img/" + route.params.id);
  }
  if (button === "right") {
    router.push("/detail/" + route.params.id);
  }
};
onMounted(() => {
  console.log(route.params.id);
});
</script>
<style scoped>
a-layout {
  height: 100vh; /* 占满整个视口的高度 */
}
a-layout-header {
  height: 20%; /* 使用百分比设置高度 */
}
a-layout-content {
  min-height: 70%; /* 使用百分比设置最小高度 */
}
a-layout-footert {
  min-height: 10%; /* 使用百分比设置最小高度 */
}
</style>
