<template>
  <div style="display: flex; width: 100%; justify-content: center">
    <a-card style="width: 90%; margin-bottom: 80px" :bordered="false">
      <div
        style="
          position: absolute;
          top: 0;
          right: 0;
          margin-top: 20px;
          background-color: #fff;
          font-size: 12px;
          padding: 5px;
          border: 1px solid #da1414;
          border-radius: 2px;
        "
      >
        销售单位未入库
      </div>
      <div style="margin-top: 40px">
        <a-space direction="vertical">
          <span style="flex: 1"><strong>电池编号:</strong></span>
          <span style="color: #a1a1a1">{{ battery.batteryId }}</span>
        </a-space>
        <p>
          <span style="flex: 1"><strong>蓄电池类型:</strong></span>
          <span style="color: #a1a1a1">{{ battery.batteryType }}</span>
        </p>
        <p>
          <span style="flex: 1"><strong>蓄电池生产企业:</strong></span>
          <span style="color: #a1a1a1">{{ battery.manufacturer }}</span>
        </p>
        <p>
          <span style="flex: 1"><strong>蓄电池容量:</strong></span>
          <span style="color: #a1a1a1">{{ battery.capacity }}</span>
        </p>
        <p>
          <span style="flex: 1"><strong>蓄电池型号:</strong></span>
          <span style="color: #a1a1a1">{{ battery.model }}</span>
        </p>
        <p>
          <span style="flex: 1"><strong>蓄电池重量:</strong></span>
          <span style="color: #a1a1a1">{{ battery.weight }}</span>
        </p>
        <p>
          <span style="flex: 1"><strong>生产日期:</strong></span>
          <span style="color: #a1a1a1">{{ time }}</span>
        </p>
      </div>
    </a-card>
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { useRoute } from "vue-router";
import { ref } from "vue";

const route = useRoute();
const batteryId = route.params.id;
const battery = ref({});
const time = ref({});
axios
  .get("http://fzoj.fun:7529/api/battery/get", {
    params: { batteryId: batteryId },
  })
  .then((res) => {
    battery.value = res.data.data;
    time.value = res.data.data.productionDate.substring(0, 10);
    console.log(time.value);
  });
</script>

<style scoped>
h2 {
  color: #333;
}

strong {
  font-size: 13.5px;
  margin-right: 12px;
}
span {
  font-size: 13.5px;
}
p {
  margin: 15px 0;
}
</style>
