<template>
  <div id="ImageView">
    <img :src="batteryImgUrl" width="95%" id="index_r3_c1" alt="" />
  </div>
</template>
<script setup lang="ts">
import axios from "axios";
import { useRoute } from "vue-router";
import { ref } from "vue";
const route = useRoute();
const batteryId = route.params.id;
const batteryImgUrl = ref({});
axios
  .get("http://fzoj.fun:7529/api/battery/get", {
    params: { batteryId: batteryId },
  })
  .then((res) => {
    batteryImgUrl.value = res.data.data.certificationImageUrl;
  });
</script>
<style scoped>
#ImageView {
  text-align: center;
}
</style>
