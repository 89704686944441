import { createStore } from "vuex";

export default createStore({
  getters: {},
  state: {
    selectedButton: "left", // 默认选中'left'
  },
  mutations: {
    setSelectedButton(state, button) {
      state.selectedButton = button;
    },
  },
  actions: {},
  modules: {},
});
