import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";
import { routes } from "./routes";
import store from "@/store";

console.log(routes);
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.path.startsWith("/img")) {
    store.commit("setSelectedButton", "left");
  } else if (to.path.startsWith("/detail")) {
    store.commit("setSelectedButton", "right");
  }
  next();
});

export default router;
